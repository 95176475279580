import moment from 'moment';

export const BASIC_DATE_FORMAT = 'd MMM yy';
export const BASIC_DATE_RANGE_FORMAT = 'DD MMM YY hh:mm A';
export const DT_FORMAT = `${BASIC_DATE_FORMAT}, h:mm aaa`;

export const defaultDateFormat = 'DD/MM/YYYY';

export const defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';

export const fullDateTimeFormat = function(timestamp){
  return moment.unix(timestamp).format(defaultDateTimeFormat)
}

export const onlyDateFormat = function(timestamp){
  return moment.unix(timestamp).format(defaultDateFormat)
}
