import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu, message, Popconfirm} from 'antd';
import {
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  LogoutOutlined,
  BarcodeOutlined,
  AppstoreOutlined,
  BankOutlined,
  IdcardOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { AppRoutes } from 'configs/AppRoutes';

import { useAuth } from 'features/auth/hooks';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

export default function Navigation() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const items = useMemo(() => {
    const items = [];

    items.push(
      getItem(
        <Link to={AppRoutes.Accounts.List.path}>
          {AppRoutes.Accounts.List.label}
        </Link>,
        AppRoutes.Accounts.List.path,
        <UsergroupAddOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Apps.List.path}>
          {AppRoutes.Apps.List.label}
        </Link>,
        AppRoutes.Apps.List.path,
        <AppstoreAddOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.OrderPayments.List.path}>
          {AppRoutes.OrderPayments.List.label}
        </Link>,
        AppRoutes.OrderPayments.List.path,
        <BarcodeOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.RecommendApps.List.path}>
          {AppRoutes.RecommendApps.List.label}
        </Link>,
        AppRoutes.RecommendApps.List.path,
        <AppstoreOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Subscriptions.List.path}>
          {AppRoutes.Subscriptions.List.label}
        </Link>,
        AppRoutes.Subscriptions.List.path,
        <BankOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Tenants.List.path}>
          {AppRoutes.Tenants.List.label}
        </Link>,
        AppRoutes.Tenants.List.path,
        <IdcardOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.CustomDomains.List.path}>
          {AppRoutes.CustomDomains.List.label}
        </Link>,
        AppRoutes.CustomDomains.List.path,
        <IdcardOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Credits.List.path}>
          {AppRoutes.Credits.List.label}
        </Link>,
        AppRoutes.Credits.List.path,
        <IdcardOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.PromoCodes.List.path}>
          {AppRoutes.PromoCodes.List.label}
        </Link>,
        AppRoutes.PromoCodes.List.path,
        <IdcardOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Affiliates.CommissionList.path}>
          {AppRoutes.Affiliates.CommissionList.label}
        </Link>,
        AppRoutes.Affiliates.CommissionList.path,
        <IdcardOutlined />
      )
    );

    items.push(
      getItem(
        <Link to={AppRoutes.Affiliates.WithdrawList.path}>
          {AppRoutes.Affiliates.WithdrawList.label}
        </Link>,
        AppRoutes.Affiliates.WithdrawList.path,
        <IdcardOutlined />
      )
    );

    return items;
  }, []);

  useEffect(() => {
    const hashPath = window.location.href;
    if (hashPath.indexOf(AppRoutes.Accounts.List.path) !== -1) {
      setSelectedKeys([AppRoutes.Accounts.List.path]);
      setOpenKeys([]);
    }
  }, []);

  const onSelectMenuItem = useCallback((item, key) => {
    setSelectedKeys([item.key]);
  }, []);

  const onOpenChange = useCallback((keys) => {
    setOpenKeys(keys);
  }, []);

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    message.success('Đã đăng xuất thành công.');
  };

  items.push(
    getItem(
      <Popconfirm
        placement="topRight"
        title={`Bạn có chắc muốn đăng xuất?`}
        onConfirm={handleLogout}
        okText="Yes"
        cancelText="No"
      >
        <a href="/">Đăng xuất</a>
      </Popconfirm>,
      null,
      <LogoutOutlined />
    )
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={items}
      selectedKeys={selectedKeys}
      onSelect={onSelectMenuItem}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    ></Menu>
  );
}
