import { Button, Form, message, Input, Col, Row } from 'antd';
import AppHeader from 'components/AppHeader';
import React, { useState, useEffect } from 'react';
import accountApis from 'api/accountApis';
import { ContentRow } from 'components/ThemeComponents';
import { StyledTable, OFFSET_HEADER } from 'components/ThemeComponents';
import { fullDateTimeFormat } from 'helpers/dateTimeHelper';

export default function ListAccountsPage() {

  const [accounts, setAccounts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [paginate, setPaginate] = useState({})
  const [searchForm] = Form.useForm();

  async function fetchAccounts(params={}) {
    setIsFetching(true);
    try {
      const res = await accountApis.listing(params);
      if (res.code === 200) {
        setAccounts(res.data ?? []);
        setPaginate({page: res.page, limit: res.limit, total: res.total})
      }
    } catch (error) {
      message.info(error.message);
    }
    setIsFetching(false);
  }

  useEffect(() => {
    fetchAccounts()
  }, []);

  const onChangeTable = async(pagination, filters, sorter) => {
    console.log(sorter)
    var params = {
      page: pagination.current,
      limit: pagination.pageSize,
      data_sort: sorter.columnKey,
      order_sort: sorter.order
    }
    const args = Object.assign({}, params, searchForm.getFieldsValue())
    await fetchAccounts(args)
  }

  const onFinishSearchForm = async (values) => {
    const params = values || {}
    console.log(params)
    await fetchAccounts(params)
  };

  const handleAccessAccount = async (id) => {
    const res = await accountApis.getLinkAccessAccount(id);
    try {
      const res = await accountApis.getLinkAccessAccount(id);
      if (res.code === 200) {
        window.open(res.access_link,'_blank');
      }
    } catch (error) {
      message.info(error.message);
    }
  };
  

  const bottom = 'bottomRight';

  const columns = [
    {
      title: 'ID',
      width: '15rem',
      render: (record) => <span>{record.id}</span>
    },
    {
      title: 'Name',
      width: '15rem',
      render: (record) => <span>{record.name}</span>
    },
    {
      title: 'Email',
      width: '20rem',
      ellipsis: {
        showTitle: false
      },
      render: (record) => <span>{record.email}</span>
    },
    {
      title: 'Last Login At',
      width: '20rem',
      render: (record) => <span>{fullDateTimeFormat(record.last_login_at)}</span>
    },
    {
      title: 'Created At',
      width: '20rem',
      key: 'created_at',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (record) => <span>{fullDateTimeFormat(record.created_at)}</span>
    },
    {
      title: 'Status',
      width: '20rem',
      ellipsis: {
        showTitle: false
      },
      render: (record) => <span>{record.status}</span>
    },
    {
      title: 'store_tenant_id',
      width: '20rem',
      ellipsis: {
        showTitle: false
      },
      render: (record) => <span>{record.store_tenant_id}</span>
    },
    {
      title: 'Chức năng',
      width: '10rem',
      render: (record) => (
        <>
          <p>
            <Button type="primary" onClick={() => handleAccessAccount(record.id)}>
              Đăng nhập
            </Button>
          </p>
        </>
      )
    }
  ];
  
  return (
    <>
      <AppHeader
        fixedTop
        pageTitle="Tất cả Accounts"
        pageHeaderExtras={
          <>
            <Button type="primary">
              Thêm
            </Button>
          </>
        }
      />
      <br />
      <ContentRow capped>
      <Form
          form={searchForm}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinishSearchForm}
          style={{marginBottom: "40px"}}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name='email'
                label='email'
              >
                <Input placeholder="Tìm theo email" />
              </Form.Item>
            </Col>
            <Col
              style={{
                textAlign: 'right',
              }}
            >
              <Button type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => {
                  searchForm.resetFields();
                  onFinishSearchForm();
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
        <StyledTable
          sticky={{ offsetHeader: OFFSET_HEADER }}
          size="small"
          scroll={{ x: 1000 }}
          loading={isFetching}
          rowKey="id"
          // @ts-ignore
          columns={columns}
          dataSource={accounts}
          onChange={onChangeTable}
          pagination={{
            defaultPageSize: paginate.limit,
            defaultCurrent: paginate.page,
            total: paginate.total,
            position: [bottom],
            showTotal: (total, range) => (
              <span style={{ left: 0, position: 'absolute' }}>
                Hiển thị {range[0]}-{range[1]} của {total} tin đã đăng
              </span>
            )
          }}
        />
      </ContentRow>
    </>
  );
}
