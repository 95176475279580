import { getAccessToken } from 'api/base';

// https://blog.devgenius.io/how-to-add-authentication-to-a-react-app-26865ecaca4b
export const useAuth = () => {
  var user = getAccessToken()

  // call this function to sign out logged in user
  const logout = () => {
    window.localStorage.removeItem('console_token')
    window.location.reload();
  };

  return {
    user,
    logout
  };
};
