import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from 'antd';
import {
  UserOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { AppRoutes } from 'configs/AppRoutes';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function GuestNavigation() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const items = [];
  items.push(
    getItem(
      <Link to={AppRoutes.Login.path}>{AppRoutes.Login.label}</Link>,
      AppRoutes.Login.path,
      <UserOutlined />
    )
  );

  items.push(
    getItem(
      <Link to={AppRoutes.Contact.path}>
        {AppRoutes.Contact.label}
      </Link>,
      AppRoutes.Contact.path,
      <SendOutlined />
    )
  );

  useEffect(() => {
    const hashPath = window.location.href;
    if (hashPath.indexOf(AppRoutes.Login.path) !== -1) {
      setSelectedKeys([AppRoutes.Login.path]);
      setOpenKeys([]);
    } else if (hashPath.indexOf(AppRoutes.About.path) !== -1) {
      setSelectedKeys([AppRoutes.About.path]);
      setOpenKeys([]);
    } else if (hashPath.indexOf(AppRoutes.Guide.path) !== -1) {
      setSelectedKeys([AppRoutes.Guide.path]);
      setOpenKeys(['posts_content']);
    } else if (hashPath.indexOf(AppRoutes.Contact.path) !== -1) {
      setSelectedKeys([AppRoutes.Contact.path]);
      setOpenKeys([]);
    }
  }, []);

  const onSelectMenuItem = useCallback(
    (item, key) => {
      setSelectedKeys([item.key]);
    },
    [selectedKeys]
  );

  const onOpenChange = useCallback((keys) => {
    setOpenKeys(keys);
  }, []);

  return (
    <Menu
      theme='dark'
      mode='inline'
      items={items}
      selectedKeys={selectedKeys}
      onSelect={onSelectMenuItem}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    ></Menu>
  );
}
