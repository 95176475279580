const AppRoutes = {
  Accounts: {
    List: {
      path: '/accounts',
      label: 'Accounts'
    }
  },
  Apps: {
    List: {
      path: '/apps',
      label: 'Apps'
    }
  },
  OrderPayments: {
    List: {
      path: '/order_payments',
      label: 'OrderPayments'
    }
  },
  RecommendApps: {
    List: {
      path: '/recommended_apps',
      label: 'RecommendApps'
    }
  },
  Subscriptions: {
    List: {
      path: '/subscriptions',
      label: 'Subscriptions'
    }
  },
  Tenants: {
    List: {
      path: '/tenants',
      label: 'Tenants'
    }
  },
  CustomDomains: {
    List: {
      path: '/custom_domains',
      label: 'CustomDomains'
    },
    Detail: {
      path: '/custom_domains/:customDomainId',
      label: 'Cập nhật CustomDomains'
    }
  },
  Credits: {
    List: {
      path: '/credits',
      label: 'Credits'
    }
  },
  PromoCodes: {
    List: {
      path: '/promo_codes',
      label: 'PromoCodes'
    },
    New: {
      path: '/promo_codes/new',
      label: 'Thêm PromoCode'
    },
    Detail: {
      path: '/promo_codes/:promoCodeId',
      label: 'Cập nhật PromoCode'
    }
  },
  Affiliates: {
    CommissionList: {
      path: '/commission-affiliates',
      label: 'Commission Affiliates'
    },
    WithdrawList: {
      path: '/withdraw-affiliates',
      label: 'Withdraw Affiliates'
    },
    WithdrawDetail: {
      path: '/affiliates/withdraw/:affiliateId',
      label: 'Xử lý lệnh rút'
    }
  },
  Login: {
    path: '/login',
    label: 'Đăng nhập'
  },
  Contact: {
    path: '/lien-he',
    label: 'Liên hệ'
  }
};

export { AppRoutes };
