import React from 'react';
import { Layout } from 'antd';
import PageLoading from 'components/PageLoading';
import { useOutlet } from 'react-router-dom';
import GuestNavigation from 'components/GuestNavigation';

const { Sider } = Layout;

export default function Unauthenticated(status) {

  const outlet = useOutlet();

  if (status === 'loading') return <PageLoading />;

  return (
    <>
      <Layout hasSider>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0
          }}
        >
          <div className="logo">
            <a href="/">Admin</a>
          </div>
          <GuestNavigation />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft: 200
          }}
        >
          {outlet}
        </Layout>
      </Layout>
    </>
  );
}
