export function formDataWithImages(form, attachmentsField) {
  let data = refactorParams(form)
  const attachmentIds = (data[attachmentsField] || []).map((obj) => obj?.record?.id).join(',');
  data[attachmentsField] = attachmentIds;
  return data;
}

export function dataWithLabelLocations(data) {
  // console.log(data)
  if(typeof data?.city_id === 'object'){
    data.city_id = data?.city_id?.value
  }
  if(typeof data?.district_id === 'object'){
    data.district_id = data?.district_id?.value
  }
  if(typeof data?.ward_id === 'object'){
    data.ward_id = data?.ward_id?.value
  }
  if(typeof data?.street_id === 'object'){
    data.street_id = data?.street_id?.value
  }
  return data;
}

export function refactorParams(form) {
  let data = form.getFieldsValue();
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null;
    }
  });

  return data;
}
