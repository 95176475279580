import { useEffect, useState, useCallback } from 'react';
import { Affix, Form, Descriptions, Button, message, Card, Image } from 'antd';
import { useParams } from 'react-router-dom';

import AppHeader from 'components/AppHeader';
import affiliateApis from 'api/affiliateApis';
import WithdrawForm from './components/form'
import { formDataWithImages } from 'helpers/formHelpers';
import moment from "moment";
import { ContentRow, Spacing } from 'components/ThemeComponents';

export default function DetailWithdrawPage() {
  let { affiliateId } = useParams();
  const [affiliate, setAffiliate] = useState({});
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await affiliateApis.detailWithdraw(affiliateId);

      if (response.code == 200) {
        const data = response.data;
        setAffiliate(data);
        form.setFieldsValue({
          ...data
        });
      } else {
        message.error(response.message ?? 'Đã có lỗi');
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let data = form.getFieldsValue();
    try {
      const res = await affiliateApis.updateWithdraw(affiliate.id, data);
      if (res.code == 200) {
        message.success(res.message);
        // window.location.reload();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
    setIsLoading(false);
  };

  const validateData = () => {
    form
      .validateFields()
      .then(() => {
        handleOnSubmit();
      })
      .catch((info) => {
        const errorMsg = 'Validate Failed: ' + info.errorFields[0].errors[0];
        form.scrollToField(info.errorFields[0].name, {
          behavior: 'smooth',
          block: 'center'
        });
        message.error(errorMsg);
      });
  };
  return (
    <>
      <AppHeader
        fixedTop
        pageTitle={`Cập nhật affiliate (ID ${affiliate.id})`}
        pageHeaderExtras={[
          <Button type="primary" onClick={validateData} loading={isLoading}>
            Cập nhật
          </Button>
        ]}
      />

      <WithdrawForm
        form={form}
        affiliate={affiliate}
      />

      <ContentRow capped>
        <Card>
          <Descriptions title="Thông tin lệnh rút">
            <Descriptions.Item label="Số tiền hoa hồng muốn rút">{affiliate?.commission_amount} VND</Descriptions.Item>
          </Descriptions>
        </Card>
      </ContentRow>

      <ContentRow capped>
        <Card>
          <Descriptions title="Tổng quan">
            <Descriptions.Item label="Tổng doanh thu">{affiliate?.results?.total_revenue_amount} VND</Descriptions.Item>
            <Descriptions.Item label="Tổng hoa hồng">{affiliate?.results?.total_commission_amount} VND</Descriptions.Item>
            <Descriptions.Item label="Tổng số tiền đã rút">{affiliate?.results?.total_withdraw_amount} VND</Descriptions.Item>
          </Descriptions>
        </Card>
      </ContentRow>

      <ContentRow capped>
        <Card>
          <Descriptions title="Bank Info">
            <Descriptions.Item label="Tên ngân hàng">{affiliate?.bank_data?.name}</Descriptions.Item>
            <Descriptions.Item label="STK">{affiliate?.bank_data?.bank_code}</Descriptions.Item>
            <Descriptions.Item label="Chi nhánh">{affiliate?.bank_data?.address}</Descriptions.Item>
            <Descriptions.Item label="Tên người hưởng thụ">{affiliate?.bank_data?.owner_name}</Descriptions.Item>
            <Descriptions.Item label="SDT">{affiliate?.bank_data?.phone_number}</Descriptions.Item>
          </Descriptions>
          {affiliate?.bank_data?.identity_cards?.map((image) => (
            <Image
              width={200}
              src={image?.image_url}
            />
          ))}
        </Card>
      </ContentRow>

    </>
  );
}
