import { Card, Tag, Layout, Table, Divider } from 'antd';
import styled from 'styled-components';
import { grey } from '@ant-design/colors';

import { convertAbsUnits } from 'utils/styleHelper';
import React from 'react';

// -------------------------
// Small Text
// -------------------------

const CellText = styled.span`
  display: ${(props) => props.display || 'inherit'};
  font-size: ${(props) => props.size || 'unset'};
  line-height: 1.2;
`;

// -------------------------
// Section Card
// -------------------------

const GUTTER = convertAbsUnits({ val: 1, to: 'px' });

const StackCard = styled(Card)`
  margin-top: ${GUTTER}px;
  box-shadow: @box-shadow-base;

  &:first-child {
    margin-top: 0;
  }
`;

const StackCardRule = styled(Divider)`
  margin: '1rem auto';
`;

// -------------------------
// Content Row
// -------------------------

const ContentRow = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: ${(props) => (props.capped ? '0.75rem' : 0)};
  margin: 0;
  background-color: ${(props) => props.filled && '#f0f2f5'};
`;

// -------------------------
// Shield Badge
// -------------------------

const ShieldWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  border-radius: 0.25rem;
`;

const ShieldSegment = styled(Tag)`
  font-size: 0.7rem;
  line-height: 1.5;
  vertical-align: middle;
  padding: 0.05rem 0.25rem;
  margin: 0;
  border-radius: 0;
`;

const Shield = ({ prefix, suffix }) => {
  const colorPrefix = prefix?.color ?? grey[4];
  const colorSuffix = suffix?.color ?? grey[1];
  return (
    <ShieldWrapper>
      {prefix && (
        <ShieldSegment color={colorPrefix}>{prefix.text}</ShieldSegment>
      )}
      {suffix && (
        <ShieldSegment color={colorSuffix}>{suffix.text}</ShieldSegment>
      )}
    </ShieldWrapper>
  );
};

// -------------------------
// Spacing
// -------------------------
const Spacing = styled.div`
  width: 10px;
  height: 10px;
`;

// -------------------------
// Others
// -------------------------

const StyledTable = styled(Table)`
  box-shadow: @box-shadow-base;
  && tbody > tr:hover > td {
    background: rgba(224, 248, 232, 1);
    cursor: pointer;
  }
`;

const InputPrefix = styled.div`
  color: ${grey[1]};
  font-size: small;
`;

const FormMessage = styled.span`
  font-size: smaller;
`;

const LayoutContent = styled(Layout.Content)`
  padding: 0.7rem;
  margin: 0;
`;

const OFFSET_HEADER = 56;

export {
  GUTTER,
  ContentRow,
  StackCard,
  StackCardRule,
  StyledTable,
  Shield,
  CellText,
  LayoutContent,
  InputPrefix,
  FormMessage,
  Spacing,
  OFFSET_HEADER
};
