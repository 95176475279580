export const baseUrl = process.env.REACT_APP_API_PATH;

export function getAccessToken() {
  return window.localStorage.getItem('console_token')
}

export function getAuthorizedHeaders() {
  const accessToken = getAccessToken();

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };
}

export async function authorizedFetch(url, config = {}) {
  try {
    const header = await getAuthorizedHeaders();
    const newConfig = { ...config, ...header };
    const res = await fetch(url, newConfig);
    if (!res.ok) {
      const error = new Error('Error while fetching data.');
      const info = await res.json();
      error.message = info?.message || 'Bad Request';
      throw error;
    }
    return await res.json();
  } catch (error) {
    throw new Error(error.toString());
  }
}

export const removeEmpty = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));

export const removeNull = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
