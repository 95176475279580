import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function listing(params = {}) {
  const path = `${baseUrl}/console/api/super_admin/tenants?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

const tenantApis = { listing };

export default tenantApis;
