import AppHeader from 'components/AppHeader';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import promoCodeApis from 'api/promoCodeApis';
import { AppRoutes } from 'configs/AppRoutes';
import PromoCodeForm from './components/form'
import moment from "moment";

export default function NewPromoCodePage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      status: 'active',
      promo_code_type: 'one_time'
    });
  }, []);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let data = form.getFieldsValue();
    data.expired_at = moment(data.expired_at, 'DD/MM/YYYY').format('DD/MM/YYYY');
    try {
      const res = await promoCodeApis.create(data);
      if (res.code == 200) {
        navigate(AppRoutes.PromoCodes.List.path);
        message.success('Tạo thành công');
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
    setIsLoading(false);
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        handleOnSubmit();
      })
      .catch((info) => {
        const errorMsg = 'Validate Failed: ' + info.errorFields[0].errors[0];
        form.scrollToField(info.errorFields[0].name, {
          behavior: 'smooth',
          block: 'center'
        });
        message.error(errorMsg);
      });
  };

  return (
    <>
      <AppHeader
        fixedTop
        pageTitle="Thêm mới PromoCode"
        pageHeaderExtras={[
          <Button type="primary" onClick={submitForm} loading={isLoading}>
            Tạo mới
          </Button>
        ]}
      />
      <PromoCodeForm
        form={form}
      />
    </>
  );
}
