export const phoneNumberRegex = /\b(\+84|84|0)(3|5|7|8|9)([0-9]{8})\b/;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const minExYear = 1;
export const maxExYear = 50;

export const minTitleCount = 30;
export const maxTitleCount = 99;

export const minDesCount = 100;
export const maxDesCount = 3000;

export const minPriceSell = 100000000;
export const minPriceRent = 1000000;

export const validateMessages = {
  default: '${label} không phù hợp!',
  required: '${label} là bắt buộc!',
  pattern: {
    mismatch: '${label} không đúng định dạng!'
  },
  types: {
    email: '${label} không đúng định dạng!',
    number: '${label} phải là số!'
  },
  number: {
    range: '${label} phải lớn hơn ${min} và nhỏ hơn ${max}!',
    len: '${label} không phù hợp!',
    min: '${label} không phù hợp!',
    max: '${label} không phù hợp!'
  },
  array: {
    len: '${label} không phù hợp!',
    min: '${label} không phù hợp!',
    max: '${label} không phù hợp!',
    range: '${label} không phù hợp!'
  },
  string: {
    len: '${label} không phù hợp!',
    min: '${label} không phù hợp!',
    max: '${label} phải ít hơn ${max} ký tự!',
    range: '${label} phải nhiều hơn ${min} và ít hơn ${max} ký tự!'
  },
  enum: '${label} không phù hợp!'
};
