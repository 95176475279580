import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function listing(params = {}) {
  const path = `${baseUrl}/console/api/super_admin/affiliates?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

async function updateWithdraw(id, params) {
  const path = `${baseUrl}/console/api/super_admin/affiliates/withdraw/${id}`;

  return await authorizedFetch(path, {
    method: 'PUT',
    body: JSON.stringify(params)
  });
}

async function detailWithdraw(id) {
  const path = `${baseUrl}/console/api/super_admin/affiliates/withdraw/${id}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

const affiliateApis = { listing, updateWithdraw, detailWithdraw };

export default affiliateApis;
