import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function listing(params = {}) {
  const path = `${baseUrl}/console/api/super_admin/promo_codes?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

async function create(params) {
  const path = `${baseUrl}/console/api/super_admin/promo_codes`;

  return await authorizedFetch(path, {
    method: 'POST',
    body: JSON.stringify(params)
  });
}

async function update(id, params) {
  const path = `${baseUrl}/console/api/super_admin/promo_codes/${id}`;

  return await authorizedFetch(path, {
    method: 'PUT',
    body: JSON.stringify(params)
  });
}

async function detail(id) {
  const path = `${baseUrl}/console/api/super_admin/promo_codes/${id}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

const promoCodeApis = { listing, create, update, detail };

export default promoCodeApis;
