import { ContentRow, Spacing } from 'components/ThemeComponents';
import { 
  Form,
  Input,
  Select,
  Card,
  message,
  DatePicker
} from 'antd';
import {
  validateMessages
} from 'helpers/validationHelper';
import { useEffect, useState, useCallback } from 'react';
import { statusData, typeData } from '../helpers';
import { defaultDateFormat } from 'helpers/dateTimeHelper';
import moment from "moment";

export default function PromoCodeForm({ form, promoCode = {} }) {
  console.log('1111')
  console.log(promoCode)
  return (
    <ContentRow capped>
      <Form
        form={form}
        name="promo_code_form"
        layout="vertical"
        validateMessages={validateMessages}
        initialValues={promoCode}
      >
        <Card
          title={'Thông tin của công việc'}
          style={{ 
            minWidth: '300px',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, min: 8, max: 32 }]}
              style={{ width: '48%' }}
            >
              <Input placeholder="Nhập mã code" />
            </Form.Item>

            <Form.Item 
              label="Trạng thái"
              name="status"
              rules={[{ required: true }]}
              style={{ width: '48%' }}
            >
              <Select options={statusData} />
            </Form.Item>

            <Form.Item 
              label="Loại mã"
              name="promo_code_type"
              rules={[{ required: true }]}
              style={{ width: '48%' }}
            >
              <Select options={typeData} />
            </Form.Item>

            <Form.Item
              label="Số lượng Credit muốn tặng"
              name="quota_credit"
              rules={[{ required: true }]}
              style={{ width: '48%' }}
            >
               <Input placeholder="Nhập số lượng Credit muốn tặng" type='number'/>
            </Form.Item>

            <Form.Item
              label="Thời gian sử dụng credit( ngày )"
              name="number_of_days"
              rules={[{ required: true }]}
              style={{ width: '48%' }}
            >
               <Input placeholder="Nhập số ngày muốn giới hạn sử dụng" type='number'/>
            </Form.Item>

            <Form.Item 
              label="Expired At" 
              name="expired_at"
              style={{ width: '48%' }}
              getValueProps={(i) => ({ value: i === undefined ? undefined : moment(typeof i === 'number' ? i * 1000 : i) })}
            >
              <DatePicker 
                placeholder="Thời gian mã hết hiệu lực"
                format={defaultDateFormat}
                style={{ width: '100%' }}
              />
            </Form.Item>

          </div>
        </Card>
      </Form>
    </ContentRow>
  );
}