import { ContentRow, Spacing } from 'components/ThemeComponents';
import { 
  Form,
  Input,
  Select,
  Card,
  message,
  DatePicker
} from 'antd';
import {
  validateMessages
} from 'helpers/validationHelper';
import { useEffect, useState, useCallback } from 'react';
import { statusData, typeData } from '../helpers';
import { defaultDateFormat } from 'helpers/dateTimeHelper';

export default function AffiliateForm({ form, affiliate = {} }) {
  return (
    <ContentRow capped>
      <Form
        form={form}
        name="withdraw_form"
        layout="vertical"
        validateMessages={validateMessages}
        initialValues={affiliate}
      >
        <Card
          title={'Thông tin'}
          style={{ 
            minWidth: '300px',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <Form.Item
              label="Note"
              name="note"
              rules={[{ required: true, min: 8, max: 32 }]}
              style={{ width: '48%' }}
            >
              <Input placeholder="Note" />
            </Form.Item>

            <Form.Item 
              label="Trạng thái"
              name="status"
              rules={[{ required: true }]}
              style={{ width: '48%' }}
            >
              <Select options={statusData} />
            </Form.Item>

          </div>
        </Card>
      </Form>
    </ContentRow>
  );
}