import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function listing(params = {}) {
  const path = `${baseUrl}/console/api/super_admin/accounts?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

async function getLinkAccessAccount(id) {
  const path = `${baseUrl}/console/api/super_admin/accounts/access-link/${id}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

const accountApis = { listing, getLinkAccessAccount };

export default accountApis;
