import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function listing(params = {}) {
  const path = `${baseUrl}/console/api/super_admin/custom_domains?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

async function update(id, params) {
  const path = `${baseUrl}/console/api/workspaces/current/custom_domains/${id}`;

  return await authorizedFetch(path, {
    method: 'PUT',
    body: JSON.stringify(params)
  });
}

async function setup_domain(id, params) {
  const path = `${baseUrl}/console/api/workspaces/current/custom_domains/${id}/setup`;

  return await authorizedFetch(path, {
    method: 'PUT',
    body: JSON.stringify(params)
  });
}


async function detail(id) {
  const path = `${baseUrl}/console/api/workspaces/current/custom_domains/${id}`;

  return await authorizedFetch(path, {
    method: 'GET'
  });
}

const tenantApis = { listing, setup_domain, update, detail};

export default tenantApis;
