import { ContentRow } from 'components/ThemeComponents';
import { 
  Form,
  Input,
  Select,
  Card
} from 'antd';
// import {
//   validateMessages
// } from 'helpers/validationHelper';
import { kindData } from '../helpers';

export default function CustomerForm({ form, category = {} }) {
  return (
    <ContentRow capped>
      <Form
        form={form}
        name="category_form"
        layout="vertical"
        // validateMessages={validateMessages}
        initialValues={category}
      >
        <Card
          title={'Thông tin danh mục'}
          style={{ 
            minWidth: '300px',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <Form.Item
              label="Tên danh mục"
              name="name"
              rules={[{ required: true }]}
              style={{ width: '100%' }}
            >
              <Input placeholder="Nhập tên danh mục" />
            </Form.Item>

            <Form.Item 
              label="Loại danh mục"
              name="kind"
              style={{ width: '100%' }}
            >
              <Select options={kindData} />
            </Form.Item>

          </div>
        </Card>
      </Form>
    </ContentRow>
  );
}