import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Button, message } from 'antd';
import { useParams } from 'react-router-dom';

import AppHeader from 'components/AppHeader';
import customDomainApis from 'api/customDomainApis';
import CustomDomainForm from './components/form'
import { refactorParams } from 'helpers/formHelpers';


export default function DetailCategoryPage() {
  let { customDomainId } = useParams();
  const [category, setCategory] = useState({});
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await customDomainApis.detail(customDomainId);

      if (response.status) {
        const data = response.data;
        setCategory(data);
        form.setFieldsValue({
          ...data,
          customer_id: data?.customer?.id
        });
      } else {
        message.error(response.message ?? 'Đã có lỗi');
      }
      setIsLoading(false);
    };

    fetchData();
  }, [customDomainId, form, setCategory]);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let data = refactorParams(form)
    try {
      const res = await customDomainApis.update(category.id, data);
      if (res.status) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
    setIsLoading(false);
  };

  const validateData = () => {
    form
      .validateFields()
      .then(() => {
        handleOnSubmit();
      })
      .catch((info) => {
        const errorMsg = 'Validate Failed: ' + info.errorFields[0].errors[0];
        form.scrollToField(info.errorFields[0].name, {
          behavior: 'smooth',
          block: 'center'
        });
        message.error(errorMsg);
      });
  };
  return (
    <>
      <AppHeader
        fixedTop
        pageTitle={`Cập nhật danh mục (ID ${category.id})`}
        pageHeaderExtras={[
          <Button type="primary" onClick={validateData} loading={isLoading}>
            Cập nhật
          </Button>
        ]}
      />

      <CustomDomainForm
        form={form}
        category={category}
      />

    </>
  );
}
