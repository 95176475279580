import React from 'react';
import styled from 'styled-components';
import { Layout, PageHeader, Affix } from 'antd';

const StyledHead = styled(Layout.Header)`
  height: auto;
  padding: 0.5rem 0;
  background-color: #fff;
  box-shadow: @box-shadow-base;
`;

const StyledPageHeader = styled(PageHeader)`
  padding: 0 0.75rem;
  margin: 0;
`;

const StyledFixedTopHeader = styled(StyledHead)`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

export default function AppHeader({
  pageCrumbs = null,
  pageTitle = null,
  tags = null,
  pageHeaderExtras = null,
  fixedTop = false,
  ...rest
}) {
  if (fixedTop) {
    return (
      <Affix offsetTop={0}>
        <StyledFixedTopHeader>
          {pageCrumbs}
          <StyledPageHeader
            title={pageTitle}
            tags={tags}
            ghost={false}
            extra={pageHeaderExtras}
            {...rest}
          />
        </StyledFixedTopHeader>
      </Affix>
    );
  } else {
    return (
      <StyledHead>
        {pageCrumbs}
        <StyledPageHeader
          title={pageTitle}
          tags={tags}
          ghost={false}
          extra={pageHeaderExtras}
          {...rest}
        />
      </StyledHead>
    );
  }
}
