import Title from 'antd/lib/typography/Title';
import { useSearchParams , Navigate} from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';

export default function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  var { user } = useAuth();

  const console_token = user || searchParams.get('console_token')

  if(console_token){
    window.localStorage.setItem('console_token', console_token)
    return <Navigate to="/" />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            alignSelf: 'center',
            justifyContent: 'center',
            marginTop: '-25%'
          }}
        >
          <Title level={2} style={{ textAlign: 'center' }}>
            Chào bạn, vui lòng đăng nhập để tiếp tục
          </Title>

        </div>
      </div>
    </>
  );
}
