import React from 'react';

import './styles/App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from 'pages/Home';
import LayoutAuthenticated from './layouts/Authenticated';
import { RecoilRoot } from 'recoil';
import { message } from 'antd';
import LoginPage from './pages/LoginPage';
import Unauthenticated from './layouts/Unauthenticated';
import { AppRoutes } from './configs/AppRoutes';
import ContactPage from './pages/ContactPage';
import { AuthProvider } from 'features/auth/contexts';
import ListAccountsPage from 'pages/accounts/list';
import ListAppsPage from 'pages/apps/list';
import ListOrderPaymentsPage from 'pages/orderPayments/list';
import ListRecommendedAppsPage from 'pages/recommendedApps/list';
import ListSubscriptionPage from 'pages/subscriptions/list';
import ListTenantsPage from 'pages/tenants/list';
import ListCustomDomainsPage from 'pages/custom_domains/list';
import DetailCustomDomainsPage from 'pages/custom_domains/detail';
import ListCreditsPage from 'pages/credits/list';
import ListPromoCodesPage from 'pages/promo_codes/list';
import NewPromoCodesPage from 'pages/promo_codes/new';
import DetailPromoCodesPage from 'pages/promo_codes/detail';
import ListCommissionAffiliatesPage from 'pages/affiliates/list_commission';
import ListWithDrawAffiliatesPage from 'pages/affiliates/list_withdraw';
import WithdrawDetailAffiliatePage from 'pages/affiliates/withdraw_detail'

function App() {
  message.config({
    duration: 2
  });
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<LayoutAuthenticated />}>
              <Route path="/" element={<Home />} />
              {/* Accounts */}
              <Route path={AppRoutes.Accounts.List.path} element={<ListAccountsPage />} />

              <Route path={AppRoutes.Apps.List.path} element={<ListAppsPage />} />

              <Route path={AppRoutes.OrderPayments.List.path} element={<ListOrderPaymentsPage />} />

              <Route path={AppRoutes.RecommendApps.List.path} element={<ListRecommendedAppsPage />} />

              <Route path={AppRoutes.Subscriptions.List.path} element={<ListSubscriptionPage />} />

              <Route path={AppRoutes.Tenants.List.path} element={<ListTenantsPage />} />

              <Route path={AppRoutes.CustomDomains.List.path} element={<ListCustomDomainsPage />} />

              <Route path={AppRoutes.CustomDomains.Detail.path} element={<DetailCustomDomainsPage />} />

              <Route path={AppRoutes.Credits.List.path} element={<ListCreditsPage />} />

              <Route path={AppRoutes.PromoCodes.List.path} element={<ListPromoCodesPage />} />
              <Route path={AppRoutes.PromoCodes.New.path} element={<NewPromoCodesPage />} />
              <Route path={AppRoutes.PromoCodes.Detail.path} element={<DetailPromoCodesPage />} />

              <Route path={AppRoutes.Affiliates.CommissionList.path} element={<ListCommissionAffiliatesPage />} />
              <Route path={AppRoutes.Affiliates.WithdrawList.path} element={<ListWithDrawAffiliatesPage />} />
              <Route path={AppRoutes.Affiliates.WithdrawDetail.path} element={<WithdrawDetailAffiliatePage />} />

            </Route>

            <Route element={<Unauthenticated />}>
              <Route path={AppRoutes.Login.path} element={<LoginPage />} />
              <Route path={AppRoutes.Contact.path} element={<ContactPage />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
